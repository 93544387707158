/**
 * Do not edit directly
 * Generated on Wed, 13 Nov 2024 11:49:57 GMT
 */

export enum ColorVariants {
  accent = 'accent',
  neutral = 'neutral',
  success = 'success',
  warning = 'warning',
  error = 'error',
  info = 'info',
  status01 = 'status01',
  status02 = 'status02',
  status03 = 'status03',
  status04 = 'status04',
  status05 = 'status05',
  status06 = 'status06',
}

export enum ButtonSizes {
  large48 = 'large48',
  medium36 = 'medium36',
  small24 = 'small24',
}

export enum CloseButtonSizes {
  large24 = 'large24',
  medium20 = 'medium20',
  small16 = 'small16',
}

export enum SizeVariants {
  size4 = 'size4',
  size8 = 'size8',
  size12 = 'size12',
  size16 = 'size16',
  size20 = 'size20',
  size24 = 'size24',
  size28 = 'size28',
  size32 = 'size32',
  size36 = 'size36',
  size48 = 'size48',
  size56 = 'size56',
  size64 = 'size64',
}

export enum TypographyVariants {
  bodyLarge = 'bodyLarge',
  bodyLargeStrong = 'bodyLargeStrong',
  bodyMedium = 'bodyMedium',
  bodyMediumStrong = 'bodyMediumStrong',
  bodySmall = 'bodySmall',
  bodySmallStrong = 'bodySmallStrong',
  descriptionLarge = 'descriptionLarge',
  descriptionLargeStrong = 'descriptionLargeStrong',
  descriptionMedium = 'descriptionMedium',
  descriptionMediumStrong = 'descriptionMediumStrong',
  descriptionSmall = 'descriptionSmall',
  descriptionSmallStrong = 'descriptionSmallStrong',
  displayLarge = 'displayLarge',
  displayLargeStrong = 'displayLargeStrong',
  displayMedium = 'displayMedium',
  displayMediumStrong = 'displayMediumStrong',
  displaySmall = 'displaySmall',
  displaySmallStrong = 'displaySmallStrong',
  heading1 = 'heading1',
  heading2 = 'heading2',
  heading3 = 'heading3',
  heading4 = 'heading4',
  heading5 = 'heading5',
}
