import React, { useMemo } from 'react';

import {
  ConceptionRateParameterEnum,
  ReproductionYAxisMode,
} from '@graphql-types';
import { ChartOptions } from 'chart.js';
import clsx from 'clsx';
import R from 'ramda';
import { match, P } from 'ts-pattern';

import {
  isSkeletonPlaceholder,
  SkeletonPlaceholder,
} from '~/shared/components/Skeleton';
import { NO_VALUE_MESSAGE } from '~/shared/constants';
import {
  DATE_RANGE_REGEXP,
  DAYS_OF_WEEK_DICT,
  formatDateRange,
} from '~/shared/helpers/date';
import { formatInt } from '~/shared/helpers/number';

import { formatBull } from '~/entities/bulls';
import { formatEmployee } from '~/entities/employees';
import { ReproductionCrDetailedRowsByEntityFragment } from '~/entities/reproductionCrReports/gql/fragments/reproductionCrDetailedRowsByEntity.graphql';

import {
  BarChart,
  CHART_COLOR_PROPS_ITERATOR,
  CHART_X_SCALE_MIN_RANGE,
  CHART_Y_SCALE_MIN_RANGE,
  DataPointsTooltip,
  DEFAULT_ZOOM_PLUGIN_PAN_OPTIONS,
  DEFAULT_ZOOM_PLUGIN_ZOOM_OPTIONS,
} from '~/features/charts';

import { ColorVariants } from '~/styles/__generated__/token-variants';
import panelStyles from '~/styles/modules/panel.module.scss';

import { REPRODUCTION_CONCEPTION_RATE_PARAMETER_ENUM_DICT } from '../../constants';

interface Props {
  /**
   * className applied to the root element
   */
  className?: string;
  /**
   * Data to display on the chart
   */
  chartData: ReproductionCrDetailedRowsByEntityFragment | SkeletonPlaceholder;
  /**
   *
   */
  crGroupBy: ConceptionRateParameterEnum;
  /**
   * Mode for y axis
   */
  yAxisMode?: ReproductionYAxisMode;
}

const CHART_HEIGHT_PX = 412;

// Small helper for match expressions readability to avoid writing a lot of typeguards
const TN = <T extends ReproductionCrDetailedRowsByEntityFragment['__typename']>(
  __typename: T
) => ({
  __typename,
});

export const ReproductionCrChart: React.FC<Props> = ({
  className,
  chartData,
  crGroupBy,
  yAxisMode = ReproductionYAxisMode.Percent_100,
}) => {
  const xAxisName = REPRODUCTION_CONCEPTION_RATE_PARAMETER_ENUM_DICT[crGroupBy];
  const xAxisLabels = useMemo(
    () =>
      match(chartData)
        .with(P.when(isSkeletonPlaceholder), R.always([]))
        .with(TN('ReproductionCrRowsByBull'), ({ bullRowNames }) =>
          bullRowNames.map(bull =>
            formatBull(bull, { prefix: '', withNumberSign: false })
          )
        )
        .with(
          TN('ReproductionCrRowsByAnimalCycle'),
          ({ animalCycleRowNames }) =>
            animalCycleRowNames.map(rowName => {
              if (!rowName) {
                return NO_VALUE_MESSAGE;
              }
              const { periodStart, periodEnd } = rowName;
              return periodStart === periodEnd
                ? formatInt(periodStart)
                : `${formatInt(periodStart)}-${formatInt(periodEnd)}`;
            })
        )
        .with(TN('ReproductionCrRowsByBullBreed'), ({ bullBreedRowNames }) =>
          bullBreedRowNames.map(
            bullBreed => bullBreed?.name ?? NO_VALUE_MESSAGE
          )
        )
        .with(TN('ReproductionCrRowsByDate'), ({ dateRowNames }) =>
          dateRowNames.map(rowName => {
            if (!rowName) {
              return NO_VALUE_MESSAGE;
            }
            const { since, till } = rowName;
            return formatDateRange(since, till).split(DATE_RANGE_REGEXP);
          })
        )
        .with(TN('ReproductionCrRowsByDows'), ({ dowRowNames }) =>
          dowRowNames.map(rowName => {
            const dow = rowName?.dow;
            return dow ? DAYS_OF_WEEK_DICT[dow] : NO_VALUE_MESSAGE;
          })
        )
        .with(TN('ReproductionCrRowsByEmployee'), ({ employeeRowNames }) =>
          employeeRowNames.map(employee =>
            formatEmployee(employee, { withFullName: false })
          )
        )
        .with(
          TN('ReproductionCrRowsByInseminationNumber'),
          ({ inseminationNumberRowNames }) =>
            inseminationNumberRowNames.map(rowName =>
              formatInt(rowName?.inseminationNumber)
            )
        )
        .with(
          TN('ReproductionCrRowsByInseminationScheme'),
          ({ inseminationSchemeRowNames }) =>
            inseminationSchemeRowNames.map(
              inseminationScheme => inseminationScheme?.name ?? NO_VALUE_MESSAGE
            )
        )
        .with(
          TN('ReproductionCrRowsByIntervalBetweenInseminations'),
          ({ intervalBetweenInseminationsRowNames }) =>
            intervalBetweenInseminationsRowNames.map(rowName => {
              if (!rowName) {
                return NO_VALUE_MESSAGE;
              }
              const { periodStart, periodEnd } = rowName;
              // null period end means infinity by contract
              return !periodEnd
                ? `Более ${formatInt(periodStart)} дней`
                : `${formatInt(periodStart)}-${formatInt(periodEnd)} дней`;
            })
        )
        .with(TN('ReproductionCrRowsByStudCode'), matchedValue =>
          matchedValue.studCodeRowNames.map(
            supplier => supplier?.name ?? NO_VALUE_MESSAGE
          )
        )
        .exhaustive(),

    [chartData]
  );

  const { datasets, chartOptions } = useMemo(() => {
    const crDataset = {
      key: 'cr',
      label: 'Плодотворные осеменения (CR)',
      ...CHART_COLOR_PROPS_ITERATOR.next(ColorVariants.success).value,
      isTogglable: true,
      data: chartData.rows?.map(row => row.cr ?? NaN) ?? [],
    };
    const uncheckedInseminationsDataset = {
      key: 'uncheckedInseminationsPercent',
      label: 'Непроверенные осеменения',
      ...CHART_COLOR_PROPS_ITERATOR.next().value,
      isTogglable: true,
      data:
        chartData.rows?.map(row => row.uncheckedInseminationsPercent ?? NaN) ??
        [],
    };

    const datasetsInternal = [crDataset, uncheckedInseminationsDataset];

    const chartOptionsInternal = {
      interaction: {
        mode: 'index',
      },
      scales: {
        x: {
          type: 'category',
          title: {
            display: true,
            text: xAxisName,
          },
          grid: {
            display: true,
            offset: false,
          },
        },
        y: {
          max:
            yAxisMode === ReproductionYAxisMode.Percent_100
              ? 100
              : Math.max(
                  1,
                  ...R.zipWith(
                    (left, right) =>
                      (left.cr ?? 0) +
                      (right.uncheckedInseminationsPercent ?? 0),
                    chartData.rows ?? [],
                    chartData.rows ?? []
                  )
                ),
        },
      },
      plugins: {
        zoom: {
          limits: {
            x: {
              min: 'original',
              max: 'original',
              minRange: CHART_X_SCALE_MIN_RANGE,
            },
            y: {
              min: 'original',
              max: 'original',
              minRange: CHART_Y_SCALE_MIN_RANGE,
            },
          },
          zoom: DEFAULT_ZOOM_PLUGIN_ZOOM_OPTIONS,
          pan: DEFAULT_ZOOM_PLUGIN_PAN_OPTIONS,
        },
      },
    } satisfies ChartOptions;

    return {
      datasets: datasetsInternal,
      chartOptions: chartOptionsInternal,
    };
  }, [chartData, yAxisMode]);

  return (
    <div className={clsx(panelStyles.borderedPanel, 'p-16', className)}>
      <BarChart
        {...{
          datasets,
          labels: xAxisLabels,
          legendClassName: 'mb-32',
          height: CHART_HEIGHT_PX,
          renderTooltip: dataPoints => {
            const dateIndex = dataPoints.at(0)?.parsed.x;
            if (R.isNil(dateIndex)) {
              return null;
            }
            let xAxisLabel = xAxisLabels.at(dateIndex);
            if (R.isNil(xAxisLabel)) {
              return null;
            }
            if (Array.isArray(xAxisLabel)) {
              xAxisLabel = xAxisLabel.join(' ');
            }
            return (
              <DataPointsTooltip
                {...{
                  title: `${xAxisName} ${xAxisLabel}`,
                  dataPoints,
                  datasets,
                  mainValueMeasurementUnit: '%',
                }}
              />
            );
          },
          chartOptions,
          isStacked: true,
          isPercents: true,
        }}
      />
    </div>
  );
};
